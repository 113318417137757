import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'skeleton-card-title',
  template: '<div class= "skeleton" style="height:20px; width: 200px; margin-bottom: 8px; background-color: #bdbdbd;" ></div>'
})
export class SkeletonCardTitle {
}

@Component({
  selector: 'skeleton-card-subtitle',
  template: '<div class="skeleton" style="height:14px; width: 300px; margin-bottom:12px; background-color: #e0e0e0;"></div>'
})
export class SkeletonCardSubTitle {
}

@Component({
  selector: 'skeleton-h1',
  template: '<div class="skeleton" style="height:40px; width: 180px; background-color: #ddd;margin-bottom:1rem"></div>'
})
export class SkeletonH1 {
}

@Component({
  selector: 'skeleton-h3',
  template: '<div class="skeleton" style="height:25px; width: 340px; background-color: #ddd;margin-bottom:1rem"></div>'
})
export class SkeletonH3 {
}

@Component({
  selector: 'skeleton-h3-span',
  template: '<div class="skeleton" style="height:25px; width: 340px; background-color: #ddd;"></div>'
})
export class SkeletonH3Span {
}

@Component({
  selector: 'skeleton-li',
  template: '<div class="skeleton" style="height:10px; width: 120px; background-color: #bdbdbd;"></div>'
})
export class SkeletonListItem {
}

@Component({
  selector: 'skeleton-dot-li',
  template: `<div class="skeleton skeleton-li" style="display: flex; padding:8px 0;">
               <div class="skeleton skeleton-dot"
               style="height:14px; width: 14px; background-color: #bdbdbd; margin-right:8px;"></div>
               <div class="skeleton skeleton-name" style="height:14px; width: 128px; background-color: #bdbdbd;"></div>
           </div>`
})
export class SkeletonDotLi {
}

@Component({
  selector: 'skeleton-dot',
  styles: [`.dot { height:16px; width: 16px; border-radius:500px;
  background-color: #bdbdbd; } :host(.diamond) div { border-radius:0;
  position: relative; width: 12px; height: 12px; left: 2px; top: 0px;}`],
  template: '<div class="dot skeleton" [ngStyle]="{backgroundColor: color}" ></div>'
})
export class SkeletonDot {
  @Input() color = '#ff0000';
}

@Component({
  selector: 'skeleton-input-with-label',
  styles: [':host{ width: 100%; display: inline-block; }'],
  template: `<div *ngIf="label; then _label else loader"></div>
              <div class="skeleton" style="height:40px; width: 100%; background-color: #ddd;"></div>
              <ng-template #_label>
                <label style="font-size: 0.875rem;">{{ label }}</label>
              </ng-template>
              <ng-template #loader>
                <div class= "skeleton" style="height:20px; width: 100px; margin-bottom: 8px; background-color: #bdbdbd;" ></div>
              </ng-template>`
})
export class SkeletonInputWithLabel {
  @Input() label: any;
}

@Component({
  selector: 'skeleton-grid',
  styles: [
    '.skeleton > .box{display: flex; flex-direction: column; background-color: #fff;}',
    `.skeleton > .box > .search {
          width: 350px;
          height: 40px;
          margin-bottom: 70px;
          background-color: #bdbdbd;
        }`,
    `.skeleton > .box > .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 26px;
          margin-bottom: 2px;
          background-color: #e0e0e0;
        }`,
    `.skeleton > .box > .row > .check-box {
          margin-left: 2px;
          height: 18px;
          width: 18px;
          background-color: #bdbdbd;
        }`,
    `.skeleton > .box > .row > .text-box {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
          width: 60px;
        }`,
    `.skeleton > .box > .row > .text-box > .text {
          height: 10px;
          width: 40px;
          border-radius: 10%;
          background-color: #bdbdbd;
        }`,
    '.skeleton > .box > .row.header {background-color: #bdbdbd;}'
  ],
  template: `<div class="skeleton">
    <div class="box column-content">
      <div *ngIf="showSearch" class="search"></div>
      <div class="row header"></div>
      <ng-container *ngFor='let index of _rows'>
        <div class="row">
          <div *ngIf="showRowSelection" class="check-box">
          </div>
          <ng-container *ngFor='let index of _columns'>
            <div class="text-box">
              <div class="text"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  `
})
export class SkeletonGrid {
  @Input() set rows(value: number) {
    this._rows = new Array(value);
  }
  @Input() set columns(value: number) {
    this._columns = new Array(value);
  }
  @Input() showSearch = true;
  @Input() showRowSelection = true;
  _rows = new Array(10);
  _columns = new Array(5);
}

@Component({
  selector: 'skeleton-fancy-grid',
  styles: [
    '.box{display: flex; flex-direction: column;}',
    `.box  .search {
          width: 300px;
          height: 40px;
          margin-bottom: 32px;
          background-color: #bdbdbd;
          border-radius: 4px;
        }`,
    `.box .action-button {
          width:156px;
          height:40px;
          border-radius:4px;
          background-color: #bdbdbd;
    }`,
    `.box > .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 72px;
          border-radius: 4px;
          margin-bottom: 8px;
          margin-left: 8px;
          background-color: #e0e0e0;
        }`,
    `.box > .row.module-row {
          margin-left:0;
          height: 65px;
    }`,
    `.box > .row > .text-box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-left: 16px;
          height: 20px;
          width: 400px;

        }`,
    `.box > .row > .text-box > .text {
          height: 10px;
          width: 400px;
          border-radius: 10%;
          background-color: #bdbdbd;
          margin-bottom: 8px;
        }`,
    `.box > .row > .text-box > .text.short {
          width: 120px;
    }
    `,
    '.box > .row.header { background-color: #bdbdbd;height:16px; margin-bottom: 24px; }',
    '.box > .row.header.column-header { background-color: #e0e0e0; margin-bottom:20px; }'
  ],
  template: `<div>
    <div class="box column-content">
      <div  *ngIf="showSearch" style="display:flex;justify-content:space-between;">
        <div class="action-button skeleton"></div>
        <div class="search skeleton"></div>
      </div>
      <div *ngIf="headers" class="row header skeleton"></div>
      <ng-container *ngFor="let section of _sections">
        <div *ngIf="headers" class="row header column-header"></div>
        <ng-container *ngFor='let row of _rows'>
          <div class="row skeleton" [ngClass]="{ 'module-row': isModuleRow }">
            <ng-container *ngFor='let column of _columns'>
              <div class="text-box">
                <div class="text"></div>
                <div class="text short"></div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div style="margin-bottom:40px;height:1px;">
      </div>
      </ng-container>
    </div>
  </div>
  `
})
export class SkeletonFancyGrid {
  @Input() set rows(value: number) {
    this._rows = new Array(value);
  }
  @Input() set columns(value: number) {
    this._columns = new Array(value);
  }
  @Input() set sections(value: number) {
    this._sections = new Array(value);
  }
  @Input() showSearch = true;
  @Input() headers = true;
  @Input() isModuleRow = false;


  _rows = new Array(4);
  _columns = new Array(1);
  _sections = new Array(3);
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SkeletonCardTitle,
    SkeletonCardSubTitle,
    SkeletonListItem,
    SkeletonDot,
    SkeletonH1,
    SkeletonH3,
    SkeletonH3Span,
    SkeletonDotLi,
    SkeletonInputWithLabel,
    SkeletonGrid,
    SkeletonFancyGrid
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SkeletonCardTitle,
    SkeletonCardSubTitle,
    SkeletonListItem,
    SkeletonDot,
    SkeletonH1,
    SkeletonH3,
    SkeletonH3Span,
    SkeletonDotLi,
    SkeletonInputWithLabel,
    SkeletonGrid,
    SkeletonFancyGrid
  ]
})
export class SkeletonModule { }
